import Swiper from "swiper";
import { Pagination } from "swiper/modules";

export const createReviews = () => {
  const container = document.querySelector<HTMLElement>(
    ".section-reviews .swiper",
  );

  if (container !== null) {
    new Swiper(container, {
      modules: [Pagination],
      slidesPerView: "auto",
      spaceBetween: 8,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
      },
    });
  }
};
